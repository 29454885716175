/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import { Whisper, Tooltip } from 'rsuite';

import PropTypes from 'prop-types';
import * as Styled from '../ConfirmarProfissionalDialogBackup/styles';
import { DarkModeContext } from '../../../../common/contexts/darkMode';
import theme from '../../../../styles/theme';

const InformacoesProfissional = ({ nome, info }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Whisper
        trigger="hover"
        placement="bottom"
        speaker={
          <Tooltip>
            {(info || '').split('\n').map((line, idx) => (
              <div key={`${line}-${idx}`}>{line}</div>
            ))}
          </Tooltip>
        }
      >
        <span>{nome}: </span>
      </Whisper>
    </div>
  );
};

// Definição de PropTypes para validação das props
InformacoesProfissional.propTypes = {
  nome: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

function CardModalConfirm({ item, camposInput, index }) {
  console.log('item', 'item');
  console.log('item', item);
  console.log('item', 'item');

  const { toggleMode } = useContext(DarkModeContext);

  function backgroundCard() {
    if (item.value === camposInput.value && toggleMode) {
      return theme.darkBlue;
    }
    if (item.value === camposInput.value) {
      return theme.lightGray;
    }
    if (item.value !== camposInput.value && toggleMode) {
      return '#1c1c24';
    }
    return '';
  }

  function colorCard() {
    
    if (item.bloqueado === true) {
      return theme.darkRed;
    }
    if (item.fav === 'V') {
      return theme.darkGreen;
    }
    if (item.trab === 'V') {
      return theme.lightBlue;
    }
    if (toggleMode) {
      return theme.lightGray;
    }
    
    return '';
  }

  return (
    <>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
          backgroundColor: backgroundCard(item),
          display: 'flex',
          width: '1200px',
          height: '120px',
          padding: '10px',
          boxShadow: '1px 1px 4px 1px #989898',
          mozBoxShadow: '1px 1px 4px 1px #989898',
          webkitBoxShadow: '1px 1px 4x 1px #989898',
          fontWeight: 'bold',
        }}
      >
        <div style={{ width: '80px' }}>
          <Styled.Image
            image={item.perfil_foto || ''}
            style={{ width: '80px', height: '80px' }}
          />
        </div>
        <div
          style={{
            paddingLeft: '3%',
            width: '800px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            flexDirection: 'column',
            color: colorCard(),
          }}
        >
          <div
            style={{
              width: '800px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {/* <p>{item.label}</p> */}
              {InformacoesProfissional({
                nome: item.label,
                info: item.desc_pontuacao,
              })}
            </div>
            {/* <div>
              <p>
                {InformacoesProfissional({
                  nome: item.label,
                  info: item.desc_pontuacao,
                })}
              </p>
            </div> */}
            <div
              style={{
                width: '1px',
              }}
            >
              <p>
                Cadastro: {item.data} - {item.hora}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                paddingRight: '3%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p>Trabalhos</p>
              </div>
              <div>
                <p>{item.trabalhos}</p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: '2%',
                paddingRight: '2%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p>Cancelamentos</p>
              </div>
              <div>
                <p>{item.cancelamentos}</p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: '2%',
                paddingRight: '2%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p>Faltas</p>
              </div>
              <div>
                <p>{item.faltas}</p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: '2%',
                paddingRight: '2%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p>Avaliação</p>
              </div>
              <div>
                <p>{item.avaliacao}</p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: '2%',
                paddingRight: '2%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p>Experiência na função?</p>
              </div>
              <div>
                <p>{item.tem_experiencia === true ? 'Sim' : 'Não'}</p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: '2%',
                paddingRight: '2%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p>Ja prestou serviço na função?</p>
              </div>
              <div>
                <p>{item.ja_trabalhou === true ? 'Sim' : 'Não'}</p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: '1%',
                paddingRight: '1%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '150px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <p>{item.bairro}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CardModalConfirm.propTypes = {
  item: PropTypes.object.isRequired,
  camposInput: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
export default CardModalConfirm;
