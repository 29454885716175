/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  TagPicker,
  SelectPicker,
  Button,
  Toggle,
  Form,
  Grid,
  Row,
  Col,
  Modal,
  MaskedInput,
} from 'rsuite';
import { toast } from 'react-toastify';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import VisulizarImagem from '~/components/VisualizarImagem';
import InputMascara from '~/components/InputMascara';
import { ToggleUpdate } from '~/components/ToggleStatus';
import { mascaraCpfCnpj, mascaraTelefone } from '~/components/Mascaras';
import api from '~/services/api';
import { TogglesStatus } from '~/pages/RecrutamentoSelecao/styles';
import { TogglesBloq } from '../../../relatorios/CarteiraClientes/styles';

import * as Styled from './styles';
import { ModalNewPassword } from './ModalNewPassword';
import { DarkModeContext } from '../../../../common/contexts/darkMode';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

function CamposDadosProfissionalEdicao({
  editar,
  camposInput,
  documentoAlterado,
  imagemAlterada,
  recuperarSenha,
  enviarConviteIugu,
  excluirPro,
  novaSenha,
  setCamposInput,
}) {
  const [imagem, setImagem] = useState();
  const [proExcluirModal, setproExcluirModal] = useState(false);
  const [Carregando, setCarregando] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInput, setOpenInput] = useState(false);
 
  const [dadosApi, setDadosApi] = useState({
    escolaridades: [],
    idiomas: [],
    tiposEstabelecimentos: [],
    descobriu_switch: [],
    genero: [],
  });
  const { toggleMode } = useContext(DarkModeContext);
  useEffect(() => {
    const buscarDados = async () => {
      const [escolaridade, idiomas, tiposEstabelecimentos, descobriu_switch, genero] =
        await Promise.all([
          api.get('escolaridade'),
          api.get('idioma'),
          api.get('tipo-estabelecimento'),
          api.get('descobriu-switch'),
          api.get('genero'),
        ]);
      setDadosApi({
        escolaridades: escolaridade.data || [],
    idiomas: idiomas.data || [],
    tiposEstabelecimentos: tiposEstabelecimentos.data || [],
    descobriu_switch: descobriu_switch.data || [],
    genero: genero.data || [],
      });
    };

    buscarDados();
    
   
  }, []);

  async function proExcluir() {
    const { id, nome } = camposInput;
    setCarregando(true);
    setproExcluirModal(false);
    try {
      await api.delete(`profissional/${id}`);
      excluirPro();
      setCarregando(false);
      toast.success(`Profissional ${nome} excluido(a) com sucesso.`);
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  async function alterStatusProfissional(e, value) {
    const { id } = camposInput;

    try {
      const statusValue = {
        id,
        status: value !== 'ativo' ? 'ativo' : 'inativo',
      };
      // setCarregando(true);
      await api.patch(`/profissional/${id}/status`, statusValue);
      // setCarregando(false);
      toast.success('Profissional Incluído com sucesso');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }

  const styleFormGroup = {
    display: 'flex',
    alignItems: 'normal',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <Styled.Panel
      header={<h4>Dados Profissional</h4>}
      collapsible
      bordered
      defaultExpanded
      style={{ overflow: 'auto', textAlignLast: 'start', margin: 'auto' }}
    >
      <Grid>
        <Row className="show-grid">
          <Col md={24} xs={24} sm={24}>
            <Col md={8} xs={24} sm={24} style={{ marginTop: '10px' }}>
              <div style={{ width: '150px' }}>
                <Styled.Image
                  image={camposInput.foto_perfil || ''}
                  style={{ marginBottom: '-15px' }}
                />
                <VisulizarImagem
                  titulo="Ampliar Imagem"
                  imagem={imagem || camposInput.foto_perfil}
                />
              </div>
              <div>
                <Form.Group>
                  <Form.ControlLabel>Substituir imagem</Form.ControlLabel>
                  <Form.Control
                    name="imagem_foto"
                    type="file"
                    style={{ width: '100%', marginBottom: '20px' }}
                    accept="image/*"
                    value={camposInput.imagem_foto || ''}
                    onChange={async (_, evento) => {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        setImagem(e.target.result);
                      };
                      reader.readAsDataURL(evento.target.files[0]);
                      imagemAlterada(evento.target.files[0]);
                    }}
                  />
                </Form.Group>
              </div>
              <ResponsiveForm
                label="Nome"
                name="nome"
                value={camposInput.nome || ''}
              />
              <ResponsiveForm
                name="email"
                value={camposInput.email || ''}
                label="Email"
              />

              <Form.Group style={styleFormGroup}>
                <Form.ControlLabel>Data Nascimento</Form.ControlLabel>

                <MaskedInput
                  className="rs-input"
                  value={camposInput.data_nascimento}
                  mask={[
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  // guide={guide}
                  // showMask={showMask}
                  // keepCharPositions={keepCharPositions}
                  placeholder={camposInput.data_nascimento}
                  style={{ width: '100%' }}
                  onChange={(valor) =>
                    setCamposInput({ ...camposInput, data_nascimento: valor })
                  }
                />
              </Form.Group>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Group style={styleFormGroup}>
                  <Form.ControlLabel>Perfil Bom</Form.ControlLabel>
                  <TogglesStatus>
                    <Form.Control
                      name="perfil_bom"
                      checked={camposInput.perfil_bom}
                      checkedChildren="Perfil bom"
                      unCheckedChildren="Não informado"
                      accepter={Toggle}
                    />
                  </TogglesStatus>
                </Form.Group>
                <Form.Group style={styleFormGroup && { marginBottom: '28px' }}>
                  <Form.ControlLabel>Perfil Analisado</Form.ControlLabel>
                  <TogglesStatus>
                    <Form.Control
                      name="perfil_analisado"
                      checked={camposInput.perfil_analisado}
                      checkedChildren="Perfil Ok"
                      unCheckedChildren="Não analisado"
                      accepter={Toggle}
                    />
                  </TogglesStatus>
                </Form.Group>
              </div>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Group style={styleFormGroup}>
                <Form.ControlLabel>Cpf/Cnpj</Form.ControlLabel>
                <MaskedInput
                  className="rs-input"
                  value={camposInput.cpf_cnpj}
                  mask={mascaraCpfCnpj}
                  // guide={guide}
                  // showMask={showMask}
                  // keepCharPositions={keepCharPositions}
                  placeholder={camposInput.cpf_cnpj}
                  style={{ width: '100%' }}
                  onChange={(valor) =>
                    setCamposInput({ ...camposInput, cpf_cnpj: valor })
                  }
                />
              </Form.Group>
              <Form.Group style={styleFormGroup}>
                <Form.ControlLabel>Telefone1</Form.ControlLabel>
                <MaskedInput
                  className="rs-input"
                  value={camposInput.telefone1}
                  mask={mascaraTelefone}
                  placeholder={camposInput.telefone1}
                  style={{ width: '100%' }}
                  onChange={(valor) =>
                    setCamposInput({ ...camposInput, telefone1: valor })
                  }
                />
              </Form.Group>
              <Form.Group style={styleFormGroup}>
                <Form.ControlLabel>Telefone2</Form.ControlLabel>
                <MaskedInput
                  className="rs-input"
                  value={camposInput.telefone2}
                  mask={mascaraTelefone}
                  // guide={guide}
                  // showMask={showMask}
                  // keepCharPositions={keepCharPositions}
                  placeholder={camposInput.telefone2}
                  style={{ width: '100%' }}
                  onChange={(valor) =>
                    setCamposInput({ ...camposInput, telefone2: valor })
                  }
                />
              </Form.Group>
              <ResponsiveForm
                label="Escolaridade"
                name="escolaridade"
                accepter={SelectPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={dadosApi.escolaridades.map((t) => {

                  return {
                    label: t.escolaridade,
                    value: t.id,
                  };
                })}
              />
              <ResponsiveForm
                label="Idiomas"
                name="idiomas"
                accepter={TagPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                // style={{ width: '300px' }}
                data={dadosApi.idiomas.map((i) => {
                  return {
                    label: i.idioma,
                    value: i.id,
                  };
                })}
              />  
              <ResponsiveForm
                label="Como descobriu a switch"
                name="descobriu_switch_id"
                accepter={SelectPicker}
                searchable={false}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={
                  (dadosApi.descobriu_switch && dadosApi.descobriu_switch.length > 0)
                  ? dadosApi.descobriu_switch.map((t) => {
                      return {
                        label: t.descricao,
                        value: t.id,
                      };
                    })
                  : []
                }
              />
              {camposInput.descobriu_switch_id === 6 ? 
                <Form.Group style={styleFormGroup}>
                <ResponsiveForm
                label="Como conheceu ?"
                name="obs_outro"
                value={camposInput.obs_outro || ''}
              />
              </Form.Group>
              : ''}
              <ResponsiveForm
                label="Genero"
                name="genero"
                accepter={SelectPicker}
                searchable={false}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={dadosApi.genero.map((t) => {
                  return {
                    label: t.genero,
                    value: t.id,
                  };
                })}
              />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <ResponsiveForm
                label="Preferência"
                name="tipo_estabelecimento"
                accepter={SelectPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={dadosApi.tiposEstabelecimentos.map((t) => {
                  return {
                    label: t.tipo_estabelecimento,
                    value: t.id,
                  };
                })}
              />
              <ResponsiveForm
                label="Chave Pix"
                name="pix"
                value={camposInput.pix || ''}
              />

              <Form.Group>
                <Form.ControlLabel>Documento</Form.ControlLabel>
                <Form.Control
                  name="documento_foto"
                  type="file"
                  style={{ width: '100%' }}
                  accept="image/*"
                  value={camposInput.documento_foto || ''}
                  onChange={async (_, evento) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      setImagem(e.target.result);
                    };
                    reader.readAsDataURL(evento.target.files[0]);
                    documentoAlterado(evento.target.files[0]);
                  }}
                />
                <VisulizarImagem
                  titulo="Ver Documento"
                  imagem={imagem || camposInput.documento}
                />
              </Form.Group>
              {recuperarSenha && (
                <Form.Group>
                  <Form.ControlLabel>Recuperar Senha</Form.ControlLabel>
                  <Button onClick={recuperarSenha} style={{ marginTop: '0px' }}>
                    Enviar Email
                  </Button>
                </Form.Group>
              )}
              {novaSenha && (
                <Form.Group>
                  <Form.ControlLabel>Alterar Senha</Form.ControlLabel>
                  <Button onClick={setOpen} style={{ marginTop: '0px' }}>
                    Criar Senha
                  </Button>
                </Form.Group>
              )}

              <Form.Group>
                <Form.ControlLabel>Enviar Convite Iugu</Form.ControlLabel>
                <Button
                  onClick={enviarConviteIugu}
                  style={{ marginTop: '0px' }}
                >
                  Enviar Convite
                </Button>
              </Form.Group>
              <Col md={8} sm={24} xs={24}>
                <Form.Group>
                  <Form.ControlLabel>P. Bloqueado:</Form.ControlLabel>
                  <TogglesBloq darkMode={toggleMode}>
                    <Form.Control
                      name="perfil_bloqueado"
                      checked={camposInput.perfil_bloqueado}
                      checkedChildren="ativo"
                      unCheckedChildren="Inativo"
                      accepter={Toggle}
                    />
                  </TogglesBloq>
                </Form.Group>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Group>
                  <Form.ControlLabel>Status:</Form.ControlLabel>
                  <TogglesStatus>
                    <Form.Control
                      name="status"
                      checked={camposInput.status}
                      checkedChildren="Ativo"
                      unCheckedChildren="Inativo"
                      accepter={Toggle}
                    />
                  </TogglesStatus>
                  {/* <TogglesBloq>
                  </TogglesBloq> */}

                  {/* <ToggleUpdate
                    rowData={camposInput}
                    column='status'
                    checked='Ativo'
                    unChecked='Inativo'
                    updateStatus={alterStatusProfissional}
                  /> */}
                </Form.Group>
              </Col>
            </Col>
          </Col>
        </Row>
      </Grid>
      <Modal
        open={proExcluirModal}
        onClose={() => setproExcluirModal(false)}
        size="xs"
      >
        <Modal.Header>Excluir Profissional</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente excluir este Profissional?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setproExcluirModal(false)} appearance="subtle">
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              setproExcluirModal(false);
              proExcluir();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalNewPassword open={open} setOpen={setOpen} novaSenha={novaSenha} />
    </Styled.Panel>
  );
}

CamposDadosProfissionalEdicao.propTypes = {
  editar: PropTypes.bool,
  camposInput: PropTypes.object,
  documentoAlterado: PropTypes.func.isRequired,
  imagemAlterada: PropTypes.func.isRequired,
  recuperarSenha: PropTypes.func,
  novaSenha: PropTypes.func,
  enviarConviteIugu: PropTypes.func,
  excluirPro: PropTypes.func,
  setCamposInput: PropTypes.func,
};

CamposDadosProfissionalEdicao.defaultProps = {
  editar: false,
  camposInput: {},
  recuperarSenha: null,
  novaSenha: null,
  enviarConviteIugu: null,
  excluirPro: null,
  setCamposInput: () => {},
};

export default CamposDadosProfissionalEdicao;
